<template>
    <div>偏好设置</div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>

</style>